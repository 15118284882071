import { get, map, filter, keyBy, pick } from 'lodash';
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';

import { oauth } from '../utils';
import { getPopups } from '../selectors';
import EditCompanyShippingAndBillingForm from '../components/EditCompanyShippingAndBillingForm';
import ErrorBoundary from '../components/ErrorBoundary';
import Overlay from '../components/Overlay';
import Select from '../components/Select';
import Loading from '../components/Loading';
import Header from './Header';
import MainSection from './MainSection';

import '../../scss/css/distributor-addresses.css';
import LegacyToolsNav from '../components/LegacyToolsNav';

const DistributorAddressApp = ({ popups, dispatch }) => {
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState();
  const [loading, setLoading] = useState();
  const [filters, setFilters] = useState({});
  const formRef = useRef(null);

  useEffect(() => {
    oauth('GET', 'company/csku-distributors', {}).then((data) => {
      setCompanies(keyBy(get(data, ['json', 'companies'], []), 'company_id'));
    });
  }, []);

  return <ErrorBoundary>
    <Header/>
    <MainSection popups={popups}>
      <div className="main-content">
        <div className="row"><LegacyToolsNav/></div>
        <div className="row">
          <div className="columns small-6">
            <Select
              placeholder="Select a distributor"
              options={filter(map(companies, ({ company_name, billing_address_id, shipping_address_id }, key) => {
                if (
                  (filters.withoutShipping && shipping_address_id) ||
                  (filters.withoutBilling && billing_address_id)
                ) {
                  return false;
                }
                return {
                  key,
                  value: company_name,
                };
              }), Boolean)}
              value={get(company, 'company_id')}
              change={(company_id) => {
                setCompany(companies[company_id]);
              }}
            />
          </div>
          <div className="columns small-3">
            <input style={{ display: 'inline-block' }} type="checkbox"
              onChange={(event) => {
                setFilters({...filters, withoutShipping: event.target.checked});
              }}
            />
            <span style={{ display: 'inline-block' }}>no shipping</span>
          </div>
          <div className="columns small-3">
            <input style={{ display: 'inline-block' }} type="checkbox"
              onChange={(event) => {
                setFilters({...filters, withoutBilling: event.target.checked});
              }}
            />
            <span style={{ display: 'inline-block' }}>no billing</span>
          </div>
        </div>
        {company && <div className="row">
          <EditCompanyShippingAndBillingForm ref={formRef}
            {...pick(company, ['company_id', 'billing_address_id', 'shipping_address_id'])}
          />
          <div className="columns small-4 offset-6">
            {loading ? <Loading/> : <button className="btn button"  onClick={async () => {
              setLoading(true);
              const { billingAddress, shippingAddress } = (await formRef.current.validateAndSave()) || {};
              const newCompany = {
                ...company,
                billing_address_id: get(billingAddress, 'address_id'),
                shipping_address_id: get(shippingAddress, 'address_id')
              };
              setCompany(newCompany);
              setCompanies({
                ...companies,
                [company.company_id]: newCompany,
              });
              setLoading(false);
            }}>Update</button>}
          </div>
        </div>}
      </div>
    </MainSection>
    <Overlay popups={popups} />
  </ErrorBoundary>;
};

const mapStateToProps = (state, ownProps) => {
  return {
    popups: getPopups(state),
  };
};

export default connect(mapStateToProps)(DistributorAddressApp);
