import { get, isEmpty, findKey } from 'lodash';
import React, { Component } from 'react';

import { oauth } from '../utils';
import * as geo from '../geo';
import Select from './Select';

const Mandatory = () => {
  return <div style={{display: 'inline-block', padding: 5, width: 3, height: 3, verticalAlign: 'top'}}>*</div>;
};

class AddressForm extends Component {

  constructor(props) {
    super(props);

    this.countryOptions = geo.countries.map(country => {
      return {key: country, value: country};
    });

    this.state = {
      errors: {},
    };

    this.mandatoryFields = [
      'address_line_1', 'address_city', 'address_country', 'address_state', 'address_postal'
    ];
  }

  validate() {
    const { address } = this.props;
    const errors = {};
    this.mandatoryFields.forEach((field) => {
      errors[field] = isEmpty(address[field]);
    });
    this.setState({
      errors
    });
    return errors;
  }

  handleAddressChange(newAddress, errors) {
    const { onChange, address } = this.props;
    this.setState({
      errors: {...this.state.errors, ...errors},
    });
    onChange({...address, ...newAddress});
  }

  renderInput({ field, readOnly, ...props }) {
    const { address } = this.props;
    const { errors } = this.state;
    const isMandatory = this.mandatoryFields.indexOf(field) > -1;
    return <div>
      <input type="text"
        style={{
          display: 'inline-block', maxWidth: '90%',
          ...(errors[field] && !readOnly ? {borderColor: 'red'} : {})
        }}
        onChange={(event) => {
          this.handleAddressChange(
            { [field]: event.target.value },
            { [field]: isMandatory && isEmpty(event.target.value) },
          );
        }} disabled={readOnly}
        value={address[field] || ''}
        {...props}
      />
      {isMandatory && <Mandatory/>}
    </div>;
  }

  render() {
    const { readOnly = false, address = {}, } = this.props;
    const { errors } = this.state;
    const stateOptions = get(geo.states, address.address_country, []).map((state) => {
      return {key: state.abbr, value: state.name};
    });
    return <div>
      {this.renderInput({ field: 'address_line_1', placeholder: '123 Street St.', readOnly })}
      {this.renderInput({ field: 'address_line_2', placeholder: 'Suite Number', readOnly })}
      {this.renderInput({ field: 'address_city', placeholder: 'City', readOnly })}
      <div>
        <div style={{display: 'inline-block', width: '90%' }}>
          <Select placeholder="Country" options={this.countryOptions} disabled={readOnly}
            value={address.address_country && {
              value: address.address_country, label: address.address_country
            }}
            error={errors['address_country'] && !readOnly}
            onChange={(country) => {
              this.handleAddressChange(
                {address_country: country.value, address_state: ''},
                {address_state: false, address_country: isEmpty(country.value)}
              );
            }}
          />
        </div>
        <Mandatory/>
      </div>
      <div>
        {isEmpty(stateOptions)
          ? this.renderInput({ field: 'address_state', placeholder: 'State/Province', readOnly })
          : [
            <div key="select" style={{ width: '90%', display: 'inline-block' }}>
              <Select
                placeholder="State/Province" options={stateOptions} disabled={readOnly}
                value={address.address_state && {
                  value: address.address_state, label: address.address_state
                }}
                error={errors['address_state'] && !readOnly}
                onChange={(state) => {
                  this.handleAddressChange({address_state: state.value}, {address_state: isEmpty(state.value)});
                }}
              />
            </div>,
            <Mandatory key="mandatory"/>
          ]
        }
      </div>
      {this.renderInput({ field: 'address_postal', placeholder: 'ZIP/Postal Code', readOnly })}
    </div>;
  }
}

export default class EditCompanyShippingAndBillingForm extends Component {
  constructor(props) {
    super(props);
    this.state = { sameAsBilling: false, billingAddress: {}, shippingAddress: {} };
    this.billingRef = React.createRef();
    this.shippingRef = React.createRef();
  }

  async loadAddress(address_id) {
    if (isEmpty(address_id)) {
      return {};
    }
    const result = await oauth('GET', `address/${address_id}`, {}).catch(() => {
      return null;
    });
    return get(result, 'json.address') || {};
  }

  async editAddress(address) {
    const { company_id } = this.props;
    if (address.address_id) {
      return await oauth('PUT', `address/${address.address_id}`, {
        ...address,
        parent_id: company_id,
      });
    } else {
      return await oauth('POST', `address`, {
        ...address,
        parent_id: company_id,
      });
    }
  }

  componentDidMount() {
      this.loadAddresses();
  }

  componentDidUpdate(prevProps) {
    const { company_id, billing_address_id, shipping_address_id } = this.props;
    if (
      company_id != prevProps.company_id ||
      billing_address_id != prevProps.billing_address_id ||
      shipping_address_id != prevProps.shipping_address_id
    ) {
      this.loadAddresses();
    }
  }

  async loadAddresses() {
    const { billing_address_id, shipping_address_id } = this.props;
    const [billingAddress, shippingAddress] = await Promise.all([
      this.loadAddress(billing_address_id),
      this.loadAddress(shipping_address_id),
    ]);
    this.setState({billingAddress, shippingAddress});
  }

  async validateAndSave() {
    const { billingAddress, shippingAddress } = this.state;
    const billingErrors = this.billingRef.current.validate();
    const shippingErrors = this.shippingRef.current.validate();
    if (!findKey(billingErrors) && !findKey(shippingErrors)) {
      const [billingResponse, shippingResponse] = await Promise.all([
        this.editAddress({
          ...billingAddress,
          address_name: 'Billing Address',
          tenant_default_billing: true,
          address_type: 'BILLING',
          parent_type: 'TENANT-BILLING',
        }),
        this.editAddress({
          ...shippingAddress,
          address_name: 'Shipping Address',
          tenant_default_shipping: true,
          address_type: 'SHIPPING',
          parent_type: 'TENANT-SHIPPING',
        })
      ]);
      return {
        billingAddress: get(billingResponse, ['json', 'address']),
        shippingAddress: get(shippingResponse, ['json', 'address']),
      };
    }
  }

  render() {
    const { sameAsBilling, billingAddress, shippingAddress } = this.state;

    return <div className="small-12 columns">
      <div className="small-12 medium-6 columns">
        <div>
          <h4 style={{ fontWeight: 'bold' }}>Billing Address</h4>
        </div>
        <AddressForm ref={this.billingRef} address={billingAddress} onChange={(address) => {
          this.setState({ billingAddress: address });
          if (this.state.sameAsBilling) {
            this.setState({ shippingAddress: address });
          }
        }}/>
      </div>
      <div className="small-12 medium-6 columns">
        <div>
          <h4 style={{ display: 'inline-block', paddingRight: 10, fontWeight: 'bold' }}>Shipping Address</h4>
          <input style={{ display: 'inline-block' }} type="checkbox"
            onChange={(event) => {
              if (event.target.checked) {
                this.setState({ sameAsBilling: true, shippingAddress: {
                  ...this.state.billingAddress,
                  address_id: get(this.state.shippingAddress, 'address_id'),
                } });
              } else {
                this.setState({ sameAsBilling: false });
              }
            }}
          />
          <span style={{ display: 'inline-block' }}>Same as billing</span>
        </div>
        <AddressForm ref={this.shippingRef} address={shippingAddress} readOnly={sameAsBilling}
          onChange={(address) => {
            this.setState({ shippingAddress: address });
          }}
        />
      </div>
    </div>;
  }
}
