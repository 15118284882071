import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const LegacyToolsNav = styled(({ className, style }) => {
  const toolsNavRef = useRef(null);

  useEffect(() => {
    // for compatibility with old jquery team/tools page, should convert the whole page to react
    const toolsNav = document.getElementById('tools-nav');
    toolsNav.style.display = 'inline-block';
    toolsNavRef.current.append(toolsNav);
  }, []);

  return <div ref={toolsNavRef} className={className} style={style}/>;
})`
  &&& {
    #tools-nav {
      height: 150px;
      
      .cs-header {
        h1 {
          font-size: 32px;
          color: #555;
          font-family: museo-slab,sans-serif;
        }
      }
      
      .cs-nav {
        height: 34px;

        ul {
          margin: 0;
          list-style: none;

          li {
            display: inline-block;
            border: 0;
            margin-left: -4px;
            border-bottom: 1px solid #eaeaea;

            a {
              color: #5ba0b4;
              display: inline-block;
              padding: 9px 7px;
              text-decoration: none;
              white-space: nowrap;
              padding-bottom: 6px;
              font-size: 14px;
            }

            a:hover {
              padding-bottom: 3px;
              border-bottom: 3px solid #d8ecf1;
            }
          }

          li.active a {
            font-weight: 700;
            border-bottom: 3px solid #5ba0b4;
            padding-bottom: 3px;
          }
        }
      }
    }
  }
`;

export default LegacyToolsNav;